
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function scopeColorArrImageVariantId1(colorOption, colorOptionIndex) {
                    var colorArr = colorOption.split('|');
                    var image = colorArr[1];
                    var variantId = colorArr[2].split('?variant=')[1];
                    return [_createElement('div', {
                            'className': 'grid-product__color-image grid-product__color-image--' + variantId,
                            'key': '13321'
                        })];
                }
                function repeatColorOption2(colorOption, colorOptionIndex, colorArr, image, variantId) {
                    return scopeColorArrImageVariantId1.apply(this, [
                        colorOption,
                        colorOptionIndex
                    ]);
                }
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                function scopeColorArrColorImageUrlVariantId4(colorOption, colorOptionIndex) {
                    var colorArr = colorOption.split('|');
                    var color = colorArr[0];
                    var image = colorArr[1];
                    var url = colorArr[2];
                    var variantId = colorArr[2].split('?variant=')[1];
                    return [_createElement('span', {
                            'className': 'color-swatch color-swatch--small color-swatch--' + color + ' color-swatch--with-image',
                            'style': { backgroundImage: 'url(' + image + '?crop=center&height=400&width=400)' },
                            'data-url': url,
                            'onMouseEnter': globalThis.Convermax.changeImage(variantId, image),
                            'onMouseLeave': globalThis.Convermax.setDefaltImage(variantId),
                            'key': '30091'
                        }, _createElement('span', { 'className': 'visually-hidden' }, color))];
                }
                function repeatColorOption5(colorOption, colorOptionIndex, colorArr, color, image, url, variantId) {
                    return scopeColorArrColorImageUrlVariantId4.apply(this, [
                        colorOption,
                        colorOptionIndex
                    ]);
                }
                function repeatI6(i, iIndex) {
                    return _createElement('span', {
                        'key': i,
                        'className': 'cm_star cm_star__' + (!(this.review_average - i) || this.review_average - i < 0.25 ? 'empty' : this.review_average - i < 0.75 ? 'half' : 'full')
                    });
                }
                return _createElement('div', {
                    'className': 'grid-item grid-product',
                    'data-product-handle': this.handle,
                    'data-product-id': this.id
                }, _createElement('div', { 'className': 'grid-item__content' }, _createElement('div', { 'className': 'grid-product__actions' }, !this.out_of_stock ? _createElement('button', {
                    'type': 'button',
                    'className': 'text-link quick-add-btn' + (this.variant_ids.length > 1 ? ' js-quick-add-form js-modal-open-quick-add' : ' js-quick-add-btn'),
                    'title': 'Add to cart',
                    'tabIndex': '-1',
                    'aria-expanded': 'false',
                    'data-id': this.variant_ids[0],
                    'key': '182'
                }, _createElement('span', { 'className': 'btn btn--circle btn--icon' }, _createElement('svg', {
                    'aria-hidden': 'true',
                    'focusable': 'false',
                    'role': 'presentation',
                    'className': 'icon icon-plus',
                    'viewBox': '0 0 64 64'
                }, _createElement('path', {
                    'className': 'cls-1',
                    'd': 'M32 9v46m23-23H9'
                })), _createElement('span', { 'className': 'icon__fallback-text' }, 'Add to cart'))) : null), _createElement('a', {
                    'href': this.url,
                    'className': 'grid-item__link'
                }, _createElement('div', { 'className': 'grid-product__image-wrap' }, _createElement('div', { 'className': 'grid-product__tags' }), _createElement('div', { 'className': 'grid__image-ratio grid__image-ratio--square' }, _createElement('img', {
                    'className': 'lazy lazyautosizes lazyloaded',
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'onError': this.onImageError,
                    'loading': 'lazy'
                })), this.product_colors && this.product_colors.length > 1 ? [_map(this.product_colors.slice(0, 4), repeatColorOption2.bind(this))] : null), _createElement('div', { 'className': 'grid-item__meta' }, _createElement('div', { 'className': 'grid-item__meta-main container-text' }, this.product_info_years || this.plytix_make || this.plytix_model || this.product_info_details ? _createElement('div', {
                    'className': 'grid-product__title title-columns',
                    'key': '1795'
                }, this.product_info_years ? _createElement('div', {
                    'className': 'grid-product__title-subelement',
                    'key': '1957'
                }, _createElement('div', { 'className': 'subelement-subtitle' }, 'Year(s):'), '\n              ', this.product_info_years, '\n            ') : null, this.plytix_make ? _createElement('div', {
                    'className': 'grid-product__title-subelement',
                    'key': '2167'
                }, _createElement('div', { 'className': 'subelement-subtitle' }, 'Make:'), '\n              ', this.plytix_make, '\n            ') : null, this.plytix_model ? _createElement('div', {
                    'className': 'grid-product__title-subelement',
                    'key': '2360'
                }, _createElement('div', { 'className': 'subelement-subtitle' }, 'Model:'), '\n              ', this.plytix_model, '\n            ') : null, this.product_info_years ? _createElement('div', {
                    'className': 'grid-product__title-subelement',
                    'key': '2557'
                }, _createElement('div', { 'className': 'subelement-subtitle' }, 'Details:'), '\n              ', this.product_info_details, '\n            ') : null) : null, _createElement('div', mergeProps({ 'className': 'grid-product__title' }, { dangerouslySetInnerHTML: { __html: this.title } })), this.product_colors && this.product_colors.length > 1 ? _createElement.apply(this, [
                    'div',
                    {
                        'className': 'grid-product__colors',
                        'key': '2889'
                    },
                    _map(this.product_colors.slice(0, 4), repeatColorOption5.bind(this)),
                    this.product_colors.length > 4 ? [_createElement('small', {
                            'className': 'color-swatch__more',
                            'key': '37601'
                        }, '+', this.product_colors.length - 4)] : null
                ]) : null), [this.review_count ? _createElement.apply(this, [
                        'div',
                        {
                            'className': 'cm_review-stars',
                            'key': '39710'
                        },
                        _map([
                            0,
                            1,
                            2,
                            3,
                            4
                        ], repeatI6.bind(this)),
                        _createElement('span', { 'className': 'cm_review-count' }, '(', this.review_count, ')')
                    ]) : null], _createElement('div', { 'className': 'list-bottom-info' }, _createElement('div', { 'className': 'grid-item__meta-secondary' }, _createElement('div', { 'className': 'grid-product__price' }, _createElement('span', { 'className': 'grid-product__price--current' }, _createElement('span', {
                    'aria-hidden': 'true',
                    'className': this.price_varies ? 'grid-product__price--from' : ''
                }, this.price_varies ? _createElement('span', { 'key': '4704' }, 'from ') : null, this.formatPrice(this.price), '\n                '), _createElement('span', { 'className': 'visually-hidden' }, this.formatPrice(this.price))))), _createElement('div', { 'className': 'list-bottom-info-right' }, _createElement('button', { 'className': 'choose-text columns-button' }, 'CHOOSE OPTIONS')))))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You might also like...')), _createElement.apply(this, [
        'div',
        { 'className': 'cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []