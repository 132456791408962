
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function SearchPageRT () {
    return _createElement(_Fragment, {}, [this.SearchVehicleWidget(function () {
            return _createElement('div', { 'className': 'cm_vehicle-widget cm_breadcrumbs cm_SearchVehicleWidget' }, this.template === 'locked' ? _createElement('div', {
                'className': 'cmTemplate_locked',
                'key': '74'
            }, _createElement('nav', { 'className': 'breadcrumbs' }, _createElement('a', { 'href': '/' }, 'Home'), _createElement('span', {}, '/'), '\n        Vehicle Search\n        ', _createElement('span', {}, '/'), '\n        ', this.vehicleString.replace(' ', ' / '), '\n      ')) : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.CustomSearchBox(function () {
            return _createElement('div', { 'className': 'cm_CustomSearchBox' }, _createElement('form', { 'onSubmit': this.preventFormSubmission }, _createElement('div', { 'className': 'cm_search-box_form-container' }, [
                [this.searchInput(function () {
                        return _createElement('div', { 'className': 'cm_searchInput' }, 'Search...');
                    }, {
                        widgetName: 'undefined',
                        items: undefined
                    })],
                _createElement('span', {
                    'className': 'cm_search-box_clear-container',
                    'key': '1292'
                }, this.inputNotEmpty ? _createElement('span', {
                    'className': 'cm_search-box_clear',
                    'onClick': this.clearInput,
                    'key': '419'
                }, '\n    ', window.innerWidth > 768 ? '\u2715' : 'Clear', '\n  ') : null),
                _createElement('button', {
                    'type': 'button',
                    'title': 'Search',
                    'aria-label': 'search button',
                    'className': 'cm_search-box_submit cm_button cm_button__primary',
                    'data-cm-role': 'add-query',
                    'key': '1294'
                }, [_createElement('svg', {
                        'viewBox': '0 0 1024 1024',
                        'className': 'icon icon-search',
                        'id': 'icon-search',
                        'key': '7530'
                    }, _createElement('path', {
                        'className': 'path1',
                        'd': 'M966.070 981.101l-304.302-331.965c68.573-71.754 106.232-165.549 106.232-265.136 0-102.57-39.942-199-112.47-271.53s-168.96-112.47-271.53-112.47-199 39.942-271.53 112.47-112.47 168.96-112.47 271.53 39.942 199.002 112.47 271.53 168.96 112.47 271.53 112.47c88.362 0 172.152-29.667 240.043-84.248l304.285 331.947c5.050 5.507 11.954 8.301 18.878 8.301 6.179 0 12.378-2.226 17.293-6.728 10.421-9.555 11.126-25.749 1.571-36.171zM51.2 384c0-183.506 149.294-332.8 332.8-332.8s332.8 149.294 332.8 332.8-149.294 332.8-332.8 332.8-332.8-149.294-332.8-332.8z'
                    }))])
            ]), [this.dropdown(function () {
                    function repeatSections1(sections, sectionsIndex) {
                        return [sections(function () {
                                function repeatItems1(items, itemsIndex) {
                                    return [items(function () {
                                            function mergeProps_text(inline, external) {
                                                var res = Object.assign({}, inline, external);
                                                if (inline.hasOwnProperty('style')) {
                                                    res.style = _.defaults(res.style, inline.style);
                                                }
                                                if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                                    res.className = external.className + ' ' + inline.className;
                                                }
                                                return res;
                                            }
                                            function mergeProps_product(inline, external) {
                                                var res = Object.assign({}, inline, external);
                                                if (inline.hasOwnProperty('style')) {
                                                    res.style = _.defaults(res.style, inline.style);
                                                }
                                                if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                                    res.className = external.className + ' ' + inline.className;
                                                }
                                                return res;
                                            }
                                            function repeatI2_product(i, iIndex) {
                                                return _createElement('span', {
                                                    'key': i,
                                                    'className': 'cm_star cm_star__' + (!(this.review_average - i) || this.review_average - i < 0.25 ? 'empty' : this.review_average - i < 0.75 ? 'half' : 'full')
                                                });
                                            }
                                            function onError1_facetValue(e) {
                                                e.target.classList.add('cm_hide');
                                            }
                                            function mergeProps_facetValue(inline, external) {
                                                var res = Object.assign({}, inline, external);
                                                if (inline.hasOwnProperty('style')) {
                                                    res.style = _.defaults(res.style, inline.style);
                                                }
                                                if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                                    res.className = external.className + ' ' + inline.className;
                                                }
                                                return res;
                                            }
                                            return this.template === 'text' ? _createElement('div', mergeProps_text({ 'className': 'cmTemplate_text' }, { dangerouslySetInnerHTML: { __html: this.textHighlight } })) : this.template === 'product' ? _createElement('div', { 'className': 'cmTemplate_product' }, _createElement('div', { 'className': 'image-block' }, _createElement('img', {
                                                'src': this.imageOrDefault(this.image),
                                                'alt': 'Image of ' + (this.removeHTML(this.sku) || this.removeHTML(this.title)),
                                                'onError': this.onImageError
                                            })), _createElement('div', { 'className': 'description-block' }, _createElement('div', mergeProps_product({ 'className': 'title' }, { dangerouslySetInnerHTML: { __html: this.title } })), _createElement('div', { 'className': 'price' }, '\n    ', this.price_varies ? `From: ${ this.formatPrice(this.price) }` : this.formatPrice(this.price), '\n  '), [this.review_count ? _createElement.apply(this, [
                                                    'div',
                                                    {
                                                        'className': 'cm_review-stars',
                                                        'key': '4680'
                                                    },
                                                    _map([
                                                        0,
                                                        1,
                                                        2,
                                                        3,
                                                        4
                                                    ], repeatI2_product.bind(this)),
                                                    _createElement('span', { 'className': 'cm_review-count' }, '(', this.review_count, ')')
                                                ]) : null])) : this.template === 'facetValue' ? _createElement('div', { 'className': 'cmTemplate_facetValue' }, this.imageUrl ? _createElement('div', {
                                                'className': 'image-block',
                                                'key': '35'
                                            }, _createElement('img', {
                                                'src': this.imageUrl,
                                                'alt': 'Image of ' + this.facetValue,
                                                'onError': onError1_facetValue.bind(this)
                                            })) : null, _createElement('div', { 'className': 'description-block' }, _createElement('div', mergeProps_facetValue({ 'className': 'title' }, { dangerouslySetInnerHTML: { __html: this.value } })), this.hitCount ? _createElement('span', {
                                                'className': 'hit-count',
                                                'key': '335'
                                            }, '(', this.hitCount, ')') : null)) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                                        }, { count: undefined })];
                                }
                                return _createElement('div', { 'className': 'section' + (this.sectionTitle ? ` ${ this.sectionTitle }` : '') }, this.sectionTitle ? _createElement('div', {
                                    'className': 'section-title-div',
                                    'key': '94'
                                }, _createElement('div', { 'className': 'item section-title' }, _createElement('b', {}, this.totalHits > 1 ? `${ this.totalHits } ` : '', this.sectionTitle), !!this.vehicleString ? ` for ${ this.vehicleString }` : '')) : null, _createElement.apply(this, [
                                    'div',
                                    { 'className': 'cmRepeater_items' },
                                    _map(this.items, repeatItems1.bind(this))
                                ]));
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_autocomplete cm_dropdown' }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cmRepeater_sections' },
                        _map(this.sections, repeatSections1.bind(this))
                    ]), this.browseAllButton ? [[this.browseAllButton(function () {
                                return _createElement('a', { 'className': 'item button-item cm_browseAllButton' }, 'Browse all ', this.totalHits, ' products');
                            }, {
                                widgetName: 'undefined',
                                items: undefined
                            })]] : null);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]));
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.SearchVehicleWidget(function () {
            return _createElement('div', { 'className': 'cm_vehicle-widget cm_header-title cm_SearchVehicleWidget' }, this.template === 'locked' ? _createElement('div', {
                'className': 'cmTemplate_locked',
                'key': '75'
            }, 'Vehicle Search ', this.vehicleString) : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], _createElement('div', { 'className': 'clear-self cm_SearchPage' }, _createElement('div', { 'className': 'cm_main-content' }, _createElement('div', { 'id': 'itemsBlock' }, [this.message(function () {
            return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired'), '\n  Product information including pricing and fitments is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                    'href': 'mailto:support@convermax.com',
                    'className': 'cm_message-link'
                }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('IncompatibleParts') ? [_createElement('div', {
                    'className': 'cm_message__incompatible',
                    'key': '4950'
                }, _createElement('div', {}, '\n    These parts will not fit your ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    Parts shown below are based on keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected ? [_createElement('div', {
                    'className': 'cm_message__universal',
                    'key': '7690'
                }, '\n  No vehicle specific products found for\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Press ', _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'href': this.searchByVehicleUrl
                }, 'here'), ' to view parts that\n  fit this vehicle.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                'className': 'cmTemplate_badRequest',
                'key': '1136'
            }, 'We failed to process your request (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nYou may ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.startOver
            }, 'clear your search'), ' and try to\nsearch for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                'className': 'cmTemplate_corrected',
                'key': '1554'
            }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any of the products we carry. We searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                'className': 'cmTemplate_networkError',
                'key': '1816'
            }, 'Network error\n', this.error ? [
                '(id: ',
                this.error.userId,
                ', timestamp: ',
                this.error.timestamp,
                ', instance: ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nWe could not reach the search server.\n') : null, this.template === 'nothing' ? _createElement('div', {
                'className': 'cmTemplate_nothing',
                'key': '2108'
            }, _createElement('div', {}, this.pageType !== 'search' && !this.isFacetsSelected ? [
                '\n    This ',
                this.pageType,
                ' doesn\'t have any\n    ',
                !!this.vehicleString ? [
                    ' parts that fit ',
                    _createElement('span', { 'key': '23371' }, '\'', this.vehicleString, '\'.')
                ] : null,
                !this.vehicleString ? [' products.'] : null
            ] : null, this.pageType === 'search' ? [_createElement('div', { 'key': '25411' }, 'No results were found for your search.')] : null, this.query || !!this.vehicleString ? [
                '\n    Press ',
                _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'onClick': this.startOver,
                    'key': '26721'
                }, 'here'),
                ' to clear\n    ',
                !this.vehicleString ? [' selection. '] : null,
                this.vehicleString && !this.isFacetsSelected ? ['\n      vehicle selection and browse all parts.\n    '] : null
            ] : null)) : null, this.template === 'timeout' ? _createElement('div', {
                'className': 'cmTemplate_timeout',
                'key': '3065'
            }, 'The request took too long.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                'className': 'cmTemplate_partial',
                'key': '3280'
            }, 'We were unable to find products that match entire query ', _createElement('span', { 'className': 'query' }, this.originalQuery), '. We\nperformed a partial search instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                'className': 'cmTemplate_unknown',
                'key': '3509'
            }, 'We failed to process your request', this.error ? [
                '\n  (',
                this.error.status ? [
                    _createElement('b', { 'key': '36550' }, 'status:'),
                    ' ',
                    this.error.status,
                    ', '
                ] : null,
                this.error.userId ? [
                    _createElement('b', { 'key': '37420' }, 'id:'),
                    ' ',
                    this.error.userId,
                    ', '
                ] : null,
                this.error.timestamp ? [
                    _createElement('b', { 'key': '38250' }, 'timestamp:'),
                    ' ',
                    this.error.timestamp,
                    ', '
                ] : null,
                _createElement('b', { 'key': '36204' }, 'instance:'),
                ' ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.customMessage(function () {
            return _createElement('div', { 'className': 'cm_message cm_customMessage' }, []);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.SearchHeader(function () {
            return _createElement('div', { 'className': 'cm_SearchHeader' }, _createElement('div', { 'className': 'cm_total-hits' }, _createElement('span', {}, this.totalHits, ' products')), _createElement('div', { 'className': 'cm_sort cm_mobile-hide sort-by' }, this.sortSelect));
        }, {
            widgetName: 'search-header',
            items: undefined
        })], [this.SearchResult(function () {
            function repeatItems1(items, itemsIndex) {
                return [items(function () {
                        function scopeColorArrImageVariantId1(colorOption, colorOptionIndex) {
                            var colorArr = colorOption.split('|');
                            var image = colorArr[1];
                            var variantId = colorArr[2].split('?variant=')[1];
                            return [_createElement('div', {
                                    'className': 'grid-product__color-image grid-product__color-image--' + variantId,
                                    'key': '13321'
                                })];
                        }
                        function repeatColorOption2(colorOption, colorOptionIndex, colorArr, image, variantId) {
                            return scopeColorArrImageVariantId1.apply(this, [
                                colorOption,
                                colorOptionIndex
                            ]);
                        }
                        function mergeProps(inline, external) {
                            var res = Object.assign({}, inline, external);
                            if (inline.hasOwnProperty('style')) {
                                res.style = _.defaults(res.style, inline.style);
                            }
                            if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                res.className = external.className + ' ' + inline.className;
                            }
                            return res;
                        }
                        function scopeColorArrColorImageUrlVariantId4(colorOption, colorOptionIndex) {
                            var colorArr = colorOption.split('|');
                            var color = colorArr[0];
                            var image = colorArr[1];
                            var url = colorArr[2];
                            var variantId = colorArr[2].split('?variant=')[1];
                            return [_createElement('span', {
                                    'className': 'color-swatch color-swatch--small color-swatch--' + color + ' color-swatch--with-image',
                                    'style': { backgroundImage: 'url(' + image + '?crop=center&height=400&width=400)' },
                                    'data-url': url,
                                    'onMouseEnter': globalThis.Convermax.changeImage(variantId, image),
                                    'onMouseLeave': globalThis.Convermax.setDefaltImage(variantId),
                                    'key': '30091'
                                }, _createElement('span', { 'className': 'visually-hidden' }, color))];
                        }
                        function repeatColorOption5(colorOption, colorOptionIndex, colorArr, color, image, url, variantId) {
                            return scopeColorArrColorImageUrlVariantId4.apply(this, [
                                colorOption,
                                colorOptionIndex
                            ]);
                        }
                        function repeatI6(i, iIndex) {
                            return _createElement('span', {
                                'key': i,
                                'className': 'cm_star cm_star__' + (!(this.review_average - i) || this.review_average - i < 0.25 ? 'empty' : this.review_average - i < 0.75 ? 'half' : 'full')
                            });
                        }
                        return _createElement('div', {
                            'className': 'grid-item grid-product',
                            'data-product-handle': this.handle,
                            'data-product-id': this.id
                        }, _createElement('div', { 'className': 'grid-item__content' }, _createElement('div', { 'className': 'grid-product__actions' }, !this.out_of_stock ? _createElement('button', {
                            'type': 'button',
                            'className': 'text-link quick-add-btn' + (this.variant_ids.length > 1 ? ' js-quick-add-form js-modal-open-quick-add' : ' js-quick-add-btn'),
                            'title': 'Add to cart',
                            'tabIndex': '-1',
                            'aria-expanded': 'false',
                            'data-id': this.variant_ids[0],
                            'key': '182'
                        }, _createElement('span', { 'className': 'btn btn--circle btn--icon' }, _createElement('svg', {
                            'aria-hidden': 'true',
                            'focusable': 'false',
                            'role': 'presentation',
                            'className': 'icon icon-plus',
                            'viewBox': '0 0 64 64'
                        }, _createElement('path', {
                            'className': 'cls-1',
                            'd': 'M32 9v46m23-23H9'
                        })), _createElement('span', { 'className': 'icon__fallback-text' }, 'Add to cart'))) : null), _createElement('a', {
                            'href': this.url,
                            'className': 'grid-item__link'
                        }, _createElement('div', { 'className': 'grid-product__image-wrap' }, _createElement('div', { 'className': 'grid-product__tags' }), _createElement('div', { 'className': 'grid__image-ratio grid__image-ratio--square' }, _createElement('img', {
                            'className': 'lazy lazyautosizes lazyloaded',
                            'src': this.imageOrDefault(this.resizeImage(this.image)),
                            'alt': this.removeHTML(this.title),
                            'onError': this.onImageError,
                            'loading': 'lazy'
                        })), this.product_colors && this.product_colors.length > 1 ? [_map(this.product_colors.slice(0, 4), repeatColorOption2.bind(this))] : null), _createElement('div', { 'className': 'grid-item__meta' }, _createElement('div', { 'className': 'grid-item__meta-main container-text' }, this.product_info_years || this.plytix_make || this.plytix_model || this.product_info_details ? _createElement('div', {
                            'className': 'grid-product__title title-columns',
                            'key': '1795'
                        }, this.product_info_years ? _createElement('div', {
                            'className': 'grid-product__title-subelement',
                            'key': '1957'
                        }, _createElement('div', { 'className': 'subelement-subtitle' }, 'Year(s):'), '\n              ', this.product_info_years, '\n            ') : null, this.plytix_make ? _createElement('div', {
                            'className': 'grid-product__title-subelement',
                            'key': '2167'
                        }, _createElement('div', { 'className': 'subelement-subtitle' }, 'Make:'), '\n              ', this.plytix_make, '\n            ') : null, this.plytix_model ? _createElement('div', {
                            'className': 'grid-product__title-subelement',
                            'key': '2360'
                        }, _createElement('div', { 'className': 'subelement-subtitle' }, 'Model:'), '\n              ', this.plytix_model, '\n            ') : null, this.product_info_years ? _createElement('div', {
                            'className': 'grid-product__title-subelement',
                            'key': '2557'
                        }, _createElement('div', { 'className': 'subelement-subtitle' }, 'Details:'), '\n              ', this.product_info_details, '\n            ') : null) : null, _createElement('div', mergeProps({ 'className': 'grid-product__title' }, { dangerouslySetInnerHTML: { __html: this.title } })), this.product_colors && this.product_colors.length > 1 ? _createElement.apply(this, [
                            'div',
                            {
                                'className': 'grid-product__colors',
                                'key': '2889'
                            },
                            _map(this.product_colors.slice(0, 4), repeatColorOption5.bind(this)),
                            this.product_colors.length > 4 ? [_createElement('small', {
                                    'className': 'color-swatch__more',
                                    'key': '37601'
                                }, '+', this.product_colors.length - 4)] : null
                        ]) : null), [this.review_count ? _createElement.apply(this, [
                                'div',
                                {
                                    'className': 'cm_review-stars',
                                    'key': '39710'
                                },
                                _map([
                                    0,
                                    1,
                                    2,
                                    3,
                                    4
                                ], repeatI6.bind(this)),
                                _createElement('span', { 'className': 'cm_review-count' }, '(', this.review_count, ')')
                            ]) : null], _createElement('div', { 'className': 'list-bottom-info' }, _createElement('div', { 'className': 'grid-item__meta-secondary' }, _createElement('div', { 'className': 'grid-product__price' }, _createElement('span', { 'className': 'grid-product__price--current' }, _createElement('span', {
                            'aria-hidden': 'true',
                            'className': this.price_varies ? 'grid-product__price--from' : ''
                        }, this.price_varies ? _createElement('span', { 'key': '4704' }, 'from ') : null, this.formatPrice(this.price), '\n                '), _createElement('span', { 'className': 'visually-hidden' }, this.formatPrice(this.price))))), _createElement('div', { 'className': 'list-bottom-info-right' }, _createElement('button', { 'className': 'choose-text columns-button' }, 'CHOOSE OPTIONS')))))));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_SearchResult' }, _createElement.apply(this, [
                'div',
                {
                    'className': 'new-grid product-grid collection-grid cmRepeater_items',
                    'data-view': this.view
                },
                _map(this.items, repeatItems1.bind(this))
            ]), [this.message(function () {
                    return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired'), '\n  Product information including pricing and fitments is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                            'href': 'mailto:support@convermax.com',
                            'className': 'cm_message-link'
                        }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('IncompatibleParts') ? [_createElement('div', {
                            'className': 'cm_message__incompatible',
                            'key': '4950'
                        }, _createElement('div', {}, '\n    These parts will not fit your ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    Parts shown below are based on keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected ? [_createElement('div', {
                            'className': 'cm_message__universal',
                            'key': '7690'
                        }, '\n  No vehicle specific products found for\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Press ', _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'href': this.searchByVehicleUrl
                        }, 'here'), ' to view parts that\n  fit this vehicle.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                        'className': 'cmTemplate_badRequest',
                        'key': '1136'
                    }, 'We failed to process your request (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nYou may ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.startOver
                    }, 'clear your search'), ' and try to\nsearch for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                        'className': 'cmTemplate_corrected',
                        'key': '1554'
                    }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any of the products we carry. We searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                        'className': 'cmTemplate_networkError',
                        'key': '1816'
                    }, 'Network error\n', this.error ? [
                        '(id: ',
                        this.error.userId,
                        ', timestamp: ',
                        this.error.timestamp,
                        ', instance: ',
                        this.error.instance,
                        ')'
                    ] : null, '.\n', _createElement('br', {}), '\nWe could not reach the search server.\n') : null, this.template === 'nothing' ? _createElement('div', {
                        'className': 'cmTemplate_nothing',
                        'key': '2108'
                    }, _createElement('div', {}, this.pageType !== 'search' && !this.isFacetsSelected ? [
                        '\n    This ',
                        this.pageType,
                        ' doesn\'t have any\n    ',
                        !!this.vehicleString ? [
                            ' parts that fit ',
                            _createElement('span', { 'key': '23371' }, '\'', this.vehicleString, '\'.')
                        ] : null,
                        !this.vehicleString ? [' products.'] : null
                    ] : null, this.pageType === 'search' ? [_createElement('div', { 'key': '25411' }, 'No results were found for your search.')] : null, this.query || !!this.vehicleString ? [
                        '\n    Press ',
                        _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'onClick': this.startOver,
                            'key': '26721'
                        }, 'here'),
                        ' to clear\n    ',
                        !this.vehicleString ? [' selection. '] : null,
                        this.vehicleString && !this.isFacetsSelected ? ['\n      vehicle selection and browse all parts.\n    '] : null
                    ] : null)) : null, this.template === 'timeout' ? _createElement('div', {
                        'className': 'cmTemplate_timeout',
                        'key': '3065'
                    }, 'The request took too long.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.retry
                    }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                        'className': 'cmTemplate_partial',
                        'key': '3280'
                    }, 'We were unable to find products that match entire query ', _createElement('span', { 'className': 'query' }, this.originalQuery), '. We\nperformed a partial search instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                        'className': 'cmTemplate_unknown',
                        'key': '3509'
                    }, 'We failed to process your request', this.error ? [
                        '\n  (',
                        this.error.status ? [
                            _createElement('b', { 'key': '36550' }, 'status:'),
                            ' ',
                            this.error.status,
                            ', '
                        ] : null,
                        this.error.userId ? [
                            _createElement('b', { 'key': '37420' }, 'id:'),
                            ' ',
                            this.error.userId,
                            ', '
                        ] : null,
                        this.error.timestamp ? [
                            _createElement('b', { 'key': '38250' }, 'timestamp:'),
                            ' ',
                            this.error.timestamp,
                            ', '
                        ] : null,
                        _createElement('b', { 'key': '36204' }, 'instance:'),
                        ' ',
                        this.error.instance,
                        ')'
                    ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.retry
                    }, 'try your search again'), '.\n') : null);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]);
        }, {
            widgetName: 'results',
            items: undefined
        })], [this.pagination(function () {
            function repeatButtons1(buttons, buttonsIndex) {
                return [buttons(function () {
                        return this.template === 'prev' ? _createElement('a', {
                            'href': this.pageLink,
                            'className': 'cm_pagination__button prev btn btn--large btn--circle btn--icon cmTemplate_prev'
                        }, _createElement('svg', {
                            'aria-hidden': 'true',
                            'focusable': 'false',
                            'role': 'presentation',
                            'className': 'icon icon-chevron-left',
                            'viewBox': '0 0 284.49 498.98'
                        }, _createElement('path', { 'd': 'M249.49 0a35 35 0 0 1 24.75 59.75L84.49 249.49l189.75 189.74a35.002 35.002 0 1 1-49.5 49.5L10.25 274.24a35 35 0 0 1 0-49.5L224.74 10.25A34.89 34.89 0 0 1 249.49 0z' }))) : this.template === 'next' ? _createElement('a', {
                            'href': this.pageLink,
                            'className': 'cm_pagination__button next btn btn--large btn--circle btn--icon cmTemplate_next'
                        }, _createElement('svg', {
                            'aria-hidden': 'true',
                            'focusable': 'false',
                            'role': 'presentation',
                            'className': 'icon icon-chevron-right',
                            'viewBox': '0 0 284.49 498.98'
                        }, _createElement('path', { 'd': 'M35 498.98a35 35 0 0 1-24.75-59.75l189.74-189.74L10.25 59.75a35.002 35.002 0 0 1 49.5-49.5l214.49 214.49a35 35 0 0 1 0 49.5L59.75 488.73A34.89 34.89 0 0 1 35 498.98z' }))) : this.template === 'delimiter' ? _createElement('span', { 'className': 'cm_pagination__button delimeter cmTemplate_delimiter' }, '...') : this.template === 'page' ? _createElement('span', { 'className': 'cm_pagination__button page' + (this.isActive ? ' current' : '') + ' cmTemplate_page' }, this.isActive ? _createElement(_Fragment, { 'key': '115' }, this.page) : null, !this.isActive ? _createElement('a', {
                            'className': 'paging-links',
                            'href': this.pageLink,
                            'key': '186'
                        }, this.page) : null) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'pagination cm_pagination' }, _createElement.apply(this, [
                'span',
                { 'className': 'cmRepeater_buttons' },
                _map(this.buttons, repeatButtons1.bind(this))
            ]));
        }, {
            widgetName: 'undefined',
            items: undefined
        })]))));
}
        export const componentNames = ["cm:SearchVehicleWidget","cm:searchInput","cm:browseAllButton","cm:dropdown","cm:CustomSearchBox","cm:SearchVehicleWidget","cm:message","cm:customMessage","cm:SearchHeader","cm:message","cm:SearchResult","cm:pagination"]